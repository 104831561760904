import * as React from 'react';
import Header from '../components/header';
import Footer from '../components/footer';
import styles from './404.module.scss';

interface NotFoundPageProps {
  location: Location,
}
export default class NotFoundPage extends React.Component<NotFoundPageProps> {

  public render() {
    return (
      <div id="top" className="d-flex flex-column w-100 h-100">
        <Header />
        <section className={`bg-white px-4 pb-4 h-100 px-sm-3 ${styles.notFoundText}`}>
          <h1 className="mt-5">Not Found</h1>
          <h3 className="my-4">Deze pagina kon niet worden gevonden.</h3>
        </section>
        <section className={`h-100 ${styles.notFoundImage}`} />
        <Footer isFixedBottom={true} isSitemapHidden={true} location={this.props.location} />
      </div>
    );
  }
}
